.container {

}

.image {
    width: 25%;
}


.thisButton {
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
    width: 60%;

}

.background {
    background-color: var(--blue);
    color: var(--white);
    border: 1px solid var(--blue);
}

.border {
    background-color: var(--white);
    color: var(--textColor);
    border: 1px solid var(--blue);
}
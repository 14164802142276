@font-face {
  font-family: 'polban-icon';
  src: url('../font/polban-icon.eot?19012369');
  src: url('../font/polban-icon.eot?19012369#iefix') format('embedded-opentype'),
       url('../font/polban-icon.woff2?19012369') format('woff2'),
       url('../font/polban-icon.woff?19012369') format('woff'),
       url('../font/polban-icon.ttf?19012369') format('truetype'),
       url('../font/polban-icon.svg?19012369#polban-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'polban-icon';
    src: url('../font/polban-icon.svg?19012369#polban-icon') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "polban-icon";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-up-1:before { content: '\0103'; } /* 'ă' */
.icon-down-1:before { content: '\0104'; } /* 'Ą' */
.icon-down-open:before { content: '\0110'; } /* 'Đ' */
.icon-left-open:before { content: '\0111'; } /* 'đ' */
.icon-right-open:before { content: '\0112'; } /* 'Ē' */
.icon-up-open:before { content: '\0113'; } /* 'ē' */
.icon-up-open-1:before { content: '\0114'; } /* 'Ĕ' */
.icon-right-open-1:before { content: '\0115'; } /* 'ĕ' */
.icon-left-open-1:before { content: '\0116'; } /* 'Ė' */
.icon-down-open-1:before { content: '\0117'; } /* 'ė' */
.icon-eye-off:before { content: '\0118'; } /* 'Ę' */
.icon-eye:before { content: '\0119'; } /* 'ę' */
.icon-cancel-circled:before { content: '\0123'; } /* 'ģ' */
.icon-cancel:before { content: '\0124'; } /* 'Ĥ' */
.icon-info-circled:before { content: '\0125'; } /* 'ĥ' */
.icon-paste-1:before { content: '\0126'; } /* 'Ħ' */
.icon-share-1:before { content: '\0127'; } /* 'ħ' */
.icon-ok:before { content: '\0128'; } /* 'Ĩ' */
.icon-icons8-external-link:before { content: '\0813'; } /* 'ࠓ' */
.icon-ok-circle:before { content: '\0814'; } /* 'ࠔ' */
.icon-resize-full:before { content: '\0815'; } /* 'ࠕ' */
.icon-transaction:before { content: '\e800'; } /* '' */
.icon-exchange-1:before { content: '\e801'; } /* '' */
.icon-transfer:before { content: '\e802'; } /* '' */
.icon-exchange-arrow:before { content: '\e803'; } /* '' */
.icon-menu:before { content: '\e804'; } /* '' */
.icon-share:before { content: '\e805'; } /* '' */
.icon-download:before { content: '\e806'; } /* '' */
.icon-chat:before { content: '\e807'; } /* '' */
.icon-copy:before { content: '\e808'; } /* '' */
.icon-send:before { content: '\e809'; } /* '' */
.icon-attach:before { content: '\e80a'; } /* '' */
.icon-deposit:before { content: '\e80b'; } /* '' */
.icon-exchange:before { content: '\e80c'; } /* '' */
.icon-withdrawal:before { content: '\e80d'; } /* '' */
.icon-email:before { content: '\e80e'; } /* '' */
.icon-location:before { content: '\e80f'; } /* '' */
.icon-mobile:before { content: '\e810'; } /* '' */
.icon-phone-1:before { content: '\e811'; } /* '' */
.icon-postalcode:before { content: '\e812'; } /* '' */
